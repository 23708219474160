@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.logo {
  width: 12rem;
}

@media screen and (max-height: 37.5rem) {
  .header {
    display: none;
  }
}

.header {
  border-bottom: 1px solid map-get($primary, primary20);
  padding: $gap-4;
}
