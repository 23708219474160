.page {
  display: flex;
  width: 50rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
