@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";


.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 24px 0 24px;
}

.illustration {
  width: 10.625rem;
  height: 10.625rem;
  display: inline-block;
  margin-bottom: 20px;
}

@media screen and (max-height: 25rem) {
  .illustration{
    display: none;
  }
}

.center_on_parent {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.header {
  color: map-get($primary, primary100);
  text-align: center;
  font-family: $font-family-text-font;
  font-size: $font-size-xlarge;
  line-height: $line-height-120;
  font-weight: $font-weight-700;
  margin-bottom: 16px;
}

.body_text {
  color: map-get($primary, primary100);
  text-align: center;
  font-family: $font-family-text-font;
  font-size: $font-size-medium;
  line-height: $line-height-default-150;
  margin-bottom: 24px;
}

.additional_text {
  color: map-get($primary, primary100);
  text-align: center;
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  line-height: $line-height-default-150;
  margin-bottom: 16px;
}

.body_text_second_line {
  color: map-get($primary, primary100);
  text-align: center;
  font-family: $font-family-text-font;
  font-size: $font-size-medium;
  line-height: $line-height-default-150;
  margin-top: -26px;
}

.errorMessage {
  margin-top: 1.75rem;
}

.accordionErrorToggle {
  margin-top: 0.563rem;
  rotate: 0deg;
  transform-origin: 50% 0;
  transform: translateY(-50%);

  * {
    outline: none !important;
    box-shadow: none !important;
  }

  button {
    padding: 0 !important;
    min-height: 0 !important;

    svg {
      width: 0.7rem !important;
    }
  }

  &.openedErrorToggle {
    rotate: 180deg;
  }
}

.errorMessage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shortErrorMessage {
  color: map-get($primary, primary100);
  font-family: $font-family-text-font;
  font-weight: $font-weight-400;
  line-height: $line-height-default-150;
  font-size: $font-size-base;
  margin-right: 1.125rem;
}

.longErrorMessage {
  color: map-get($primary, primary100);
  background-color: map-get($primary, primary10);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  line-height: $line-height-default-150;
  padding: $gap-2 $gap-4 $gap-2 $gap-4;
  max-width: 25rem;
  margin-top: 0.625rem;
  border-radius: 0.75rem;
}
