@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/fonts";

.loading {
  margin-top: 100px;
}

.linkCreationDescription {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin-bottom: 1rem;
}

.title {
  font-family: $font-family-dm-serif-display;
  font-weight: $font-weight-700;
  font-size: $font-size-xlarge;
  color: map-get($primary, primary100);
}

.successIllustration {
  margin-top: 3rem;
}
