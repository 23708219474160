@use "sass:map";
@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.unit {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $gap-1;
  column-gap: $gap-1;

  label {
    background-color: $color-white !important;
  }
}

.numberOfActivities {
  width: 3ch;
  min-width: 3ch;
  max-width: 3ch;
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  line-height: $line-height-120;
  text-align: $text-align-center;
}

.checkedUnit {
  color: map.get($primary, primary70);
  font-weight: $font-weight-400;
}

.name {
  min-width: calc(100% - 7ch - 2rem - $gap-4);
  max-width: calc(100% - 7ch - 2rem - $gap-4);
  margin-left: $gap-1;
  color: map.get($primary, primary100);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  line-height: $line-height-120;
}

.classroomName {
  color: map.get($primary, primary100);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-700;
  line-height: $line-height-120;
}

.overNine {
  padding: 0 15px 0 6px;
}

.classroomUnit {
  padding-bottom: $gap-2;
  border-bottom: $border-width-1 solid map.get($primary, primary20);
  margin-bottom: $gap-2;

  .numberOfActivities {
    color: map.get($primary, primary100);
  }

  .uncheckedUnit.numberOfActivities {
    color: map.get($primary, primary70);
  }
}

.childrenUnits {
  display: block;
  margin-left: calc(3ch + $gap-2 + 2px);
}

.accordionToggle {
  position: absolute;
  top: 50%;
  right: 0;
  rotate: 180deg;
  transform: translateY(-50%);
  transform-origin: 50% 0;

  button {
    min-height: 0 !important;
    padding: 0 !important;

    svg {
      width: auto !important;
    }
  }

  &.openedToggle {
    rotate: 0deg;
  }
}

.selectAllUnit {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid map.get($primary-colors, primary);
  margin-bottom: 0.5rem;
}

.uncheckedUnit {
  color: map.get($primary, primary70);
  font-weight: $font-weight-400;
}
