@use "sass:map";
@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.icon {
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  background-color: map.get($primary-colors, primary);
  cursor: pointer;

  &:focus {
    border-color: $color-borders-v2;
    box-shadow: inset 0 1px 1px $contrast-light-shadow, 0 0 10px 1px $contrast-light-blue;
    outline: none;
  }

  &:hover {
    background-color: $color-astronaut-blue;
  }

  svg {
    height: 1.5rem;
    fill: $color-white;
  }
}

.list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid map.get($primary, primary20);
}

.info {
  display: flex;
  align-items: center;
}

.brokenSingle {
  margin: $gap-4 $gap-3 $gap-4 $gap-4;
  line-height: 0;

  img {
    width: 2.75rem;
    border: 1px solid map.get($primary, primary20);
    border-radius: $border-radius-small ;
    background-color: map.get($primary, primary100);
  }
}


.brokenMultiple {
  margin: $gap-4 $gap-3 $gap-4 $gap-2;
  line-height: 0;

  img {
    width: 2.75rem;
    border: 1px solid map.get($primary, primary20);
    border-radius: $border-radius-small ;
    background-color: map.get($primary, primary100);
  }
}

.confirmSingleLabel {
  margin-top: 0;
}


.title {
  margin-top: $gap-1;
  color: map.get($primary, primary100);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  line-height: $line-height-default-150;
}

.line {
  margin-top: 15px;
}

.checkbox {
  margin: $gap-4 $gap-0 $gap-4 $gap-4;

  label {
    background-color: $color-white !important;
  }
}

.label {
  margin: $gap-4 0;
}

hr:last-child {
  display: none;
}

.menu {
  position: relative;
}

.iconMenu>button {
  max-width: 2.5rem !important;
  max-height: 2.5rem !important;
}

.pack {
  padding-right: 15px;
  margin-top: 5px;
  color: map.get($validation, dark_green);
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: open-sans;
}

.toggle {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.toggleTitle {
  color: map.get($primary-colors, primary);
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: open-sans;
}

.toggleInfo {
  display: flex;
  margin-top: 10px;
  color: map.get($primary-colors, primary);
  gap: 10px;

  span {
    font-size: 17px;
    font-weight: bold;
  }

  a {
    text-decoration: underline !important;
  }
}

.container {
  display: flex;
  align-items: stretch;
}

.container> :first-child {
  align-self: center;
}


.oxfordHubItem {
  display: flex;
  align-items: center;
  align-self: center;

  >p {
    display: flex;
    align-items: center;
    margin: 0;
  }
}

.selectButtonContainer {
  margin: $gap-4 $gap-4 $gap-4 $gap-3;
}


.HubDropdownMenu {
  fill: map.get($primary, primary100) !important;
}
