@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/mixins/sr-only";
@import "~@oup/shared-front-end/dist/styles/animations";


$barWidth: 2.25rem;
$barPadding: 0.125rem;
$barBorderWidth: 0.1rem;
$circleDiameter: 1.25rem;

.toggleLabel {
  @include sr-only();
}

.bar {
  position: relative;
  display: flex;
  width: $barWidth;
  min-width: 2.25rem;
  height: 1.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: $barPadding;
  border-width: $barBorderWidth;
  border-style: solid;
  border-radius: $border-radius-large;
  outline: none;
}

.circle {
  position: absolute;
  left: $barBorderWidth;
  display: flex;
  width: $circleDiameter;
  height: $circleDiameter;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-radius: $border-radius-round;

  @media (prefers-reduced-motion: no-preference) {
    transition: $transition-duration-1 all;
  }
}

.icon {
  position: relative;
  display: flex;
  width: $icon-size-xsmall;
  height: $icon-size-xsmall;
  box-sizing: border-box;
  padding: 0.125rem;
}

.off {
  &:not(.disabled) {
    &.bar {
      background-color: map-get($primary, primary60);

      box-shadow: $box-shadow-outline;
    }

    .circle {
      background-color: map-get($primary, primary10);
    }

    .icon {
      color: map-get($primary, primary60);
    }

    &:focus {
      &.bar {
        background-color: map-get($primary, primary60);

        box-shadow: $box-shadow-focus;
      }

      .circle {
        background-color: map-get($primary, primary10);
      }

      .icon {
        color: map-get($primary, primary60);
      }
    }

    &:hover {
      &.bar {
        background-color: map-get($primary, primary80);
        cursor: pointer;

        box-shadow: $box-shadow-outline;
      }

      .circle {
        background-color: map-get($primary, primary10);
      }

      .icon {
        color: map-get($primary, primary60);
      }
    }

    &:hover:focus {
      &.bar {
        background-color: map-get($primary, primary80);
        cursor: pointer;

        box-shadow: $box-shadow-focus;
      }

      .circle {
        background-color: map-get($primary, primary10);
      }

      .icon {
        color: map-get($primary, primary60);
      }
    }
  }

  &.disabled {
    &.bar {
      border-width: $border-width-1;
      border-color: map-get($primary, primary50);
      background-color: transparent;

      box-shadow: $box-shadow-outline;
    }

    .circle {
      border-width: $border-width-1;
      border-color: map-get($primary, primary50);
      background-color: transparent;
    }

    .icon {
      color: map-get($primary, primary50);
    }
  }
}

.on {
  .circle {
    left: calc($barWidth - $circleDiameter - $barPadding - $barBorderWidth);
  }

  &:not(.disabled) {
    &.bar {
      background-color: map-get($primary, primary100);

      box-shadow: $box-shadow-outline
    }

    .circle {
      background-color: map-get($base, white100);
    }

    .icon {
      color: map-get($primary, primary100);
    }

    &:focus {
      &.bar {
        background-color: map-get($primary, primary100);

        box-shadow: $box-shadow-focus;
      }

      .circle {
        background-color: map-get($base, white100);
      }

      .icon {
        color: map-get($primary, primary100);
      }
    }

    &:hover {
      &.bar {
        background-color: map-get($primary, primary80);
        cursor: pointer;

        box-shadow: $box-shadow-outline;
      }

      .circle {
        background-color: map-get($base, white100);
      }

      .icon {
        color: map-get($primary, primary80);
      }
    }

    &:hover:focus {
      &.bar {
        background-color: map-get($primary, primary80);
        cursor: pointer;

        box-shadow: $box-shadow-focus;
      }

      .circle {
        background-color: map-get($base, white100);
      }

      .icon {
        color: map-get($primary, primary80);
      }
    }
  }

  &.disabled {
    &.bar {
      border-width: $border-width-1;
      border-color: map-get($primary, primary50);
      background-color: transparent;

      box-shadow: $box-shadow-outline;
    }

    .circle {
      border-width: $border-width-1;
      border-color: map-get($primary, primary50);
      background-color: transparent;
    }

    .icon {
      color: map-get($primary, primary50);
    }
  }
}
