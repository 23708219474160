@use "sass:map";
@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/animations";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background-color: rgb(0 0 0 / 75%);
}

.modal {
  position: relative;
  display: flex;
  overflow: auto;
  max-width: 37.5rem;
  max-height: 90vh;
  flex-direction: column;
  align-items: center;
  border-radius: $border-radius-large;
  margin: $gap-4;
  background-color: #fff;
  box-shadow: $box-shadow-5;

  button {
    position: absolute;
    top: $gap-4;
    right: $gap-4;
  }
}

.broken {
  width: 2.75rem;
  height: 3.625rem;

  img {
    width: 2.75rem;
    height: 3.625rem;
    border: 1px solid map.get($primary, primary20);
    border-radius: $border-radius-small ;
    background-color: map.get($primary, primary100);
  }
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.wrapper_top {
  width: 100%;
}

.wrapper_bottom {
  width: 100%;
  overflow-y: auto;

    span {
      color: map.get($primary, primary100);
      font-family: $font-family-text-font;
      font-weight: $font-weight-700;
      line-height: $line-height-120;
    }
}

.cover {
  display: flex;
  align-items: center;
  padding-top: $gap-4;
  padding-bottom: $gap-4;
  margin-top: 35px;
  gap: 12px;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    color: map.get($primary, primary100);
    font-family: $font-family-text-font;
    font-size: $font-size-xlarge;
    font-weight: $font-weight-700;
    line-height: $line-height-120;
  }
}

.border {
  padding: $gap-1 0 $gap-1 0;
  border-top: 1px solid map.get($primary, primary20);
  margin-top: 0;
  color: map.get($primary, primary100);
  font-family: $font-family-text-font;
  font-weight: $font-weight-400;
  line-height: $line-height-default-150;
}

.modal {
  border: 0;

  @media ( prefers-reduced-motion: no-preference ) {
    transition-behavior: allow-discrete;
    transition-duration: $transition-duration_4;
    transition-property: opacity overlay display;
  }

  &[open] {
    display: block;
    opacity: 1;
    translate: 0 0;

    @starting-style {
      opacity: 0;
      translate: 0 -50vh;
    }
  }

  &::backdrop {
    background-color:  black;
    opacity: 0;

    @media ( prefers-reduced-motion: no-preference ) {
      transition-behavior: allow-discrete;
      transition-duration: $transition-duration_4;
      transition-property: opacity overlay display ;
    }
  }

  &[open]::backdrop {
    opacity: 0.75;

    @starting-style {
      opacity: 0;
    }
  }
}
